export const shareholding = [
    {
        id: -1,
        Heading1: "FY 2024",
        Heading2: "Quarter 3",
        PDF: "https://educomp.com/Data/Q3_SO_24.pdf",
        isActive: 1
    },
    {
        id: 1,
        Heading1: "FY 2017",
        Heading2: "Quarter 1",
        PDF: "https://educomp.com/Data/Q1SO_17.pdf",
        isActive: 1
    },
    {
        id: 2,
        Heading1: "FY 2017",
        Heading2: "Quarter 2",
        PDF: "https://educomp.com/Data/Q2SO_17.pdf",
        isActive: 1
    },
    {
        id: 3,
        Heading1: "FY 2017",
        Heading2: "Quarter 3",
        PDF: "https://educomp.com/Data/Q3SO_17.pdf",
        isActive: 1
    },
    {
        id: 4,
        Heading1: "FY 2016",
        Heading2: "Quarter 1",
        PDF: "https://educomp.com/Data/Q1SO_16.pdf",
        isActive: 1
    },
    {
        id: 5,
        Heading1: "FY 2016",
        Heading2: "Quarter 2",
        PDF: "https://educomp.com/Data/Q2SO_16.pdf",
        isActive: 1
    },
    {
        id: 6,
        Heading1: "FY 2016",
        Heading2: "Quarter 3",
        PDF: "https://educomp.com/Data/Q3SO_16.pdf",
        isActive: 1
    },
    {
        id: 7,
        Heading1: "FY 2016",
        Heading2: "Quarter 4",
        PDF: "https://educomp.com/Data/Q4SO_16.pdf",
        isActive: ""
    },
    {
        id: 8,
        Heading1: "FY 2015",
        Heading2: "Quarter 1",
        PDF: "https://educomp.com/Data/Q1SO_15.pdf",
        isActive: 1
    },
    {
        id: 9,
        Heading1: "FY 2015",
        Heading2: "Quarter 2",
        PDF: "https://educomp.com/Data/Q2SO_15.pdf",
        isActive: 1
    },
    {
        id: 10,
        Heading1: "FY 2015",
        Heading2: "Quarter 3",
        PDF: "https://educomp.com/Data/Q3SO_15.pdf",
        isActive: 1
    },
    {
        id: 11,
        Heading1: "FY 2015",
        Heading2: "Quarter 4",
        PDF: "https://educomp.com/Data/Q4SO_15.pdf",
        isActive: 1
    },
    {
        id: 12,
        Heading1: "FY 2014",
        Heading2: "Quarter 1",
        PDF: "https://educomp.com/Data/Q1SO_14.pdf",
        isActive: 1
    },
    {
        id: 13,
        Heading1: "FY 2014",
        Heading2: "Quarter 2",
        PDF: "https://educomp.com/Data/Q2SO_14.pdf",
        isActive: 1
    },
    {
        id: 14,
        Heading1: "FY 2014",
        Heading2: "Quarter 3",
        PDF: "https://educomp.com/Data/Q3SO_14.pdf",
        isActive: 1
    },
    {
        id: 15,
        Heading1: "FY 2014",
        Heading2: "Quarter 4",
        PDF: "https://educomp.com/Data/Q4SO_14.pdf",
        isActive: 1
    },
    {
        id: 16,
        Heading1: "FY 2013",
        Heading2: "Quarter 1",
        PDF: "https://educomp.com/Data/Q1SO_13.pdf",
        isActive: 1
    },
    {
        id: 17,
        Heading1: "FY 2013",
        Heading2: "Quarter 2",
        PDF: "https://educomp.com/Data/Q2SO_13.pdf",
        isActive: 1
    },
    {
        id: 18,
        Heading1: "FY 2013",
        Heading2: "Quarter 3",
        PDF: "https://educomp.com/Data/Q3SO_13.pdf",
        isActive: 1
    },
    {
        id: 19,
        Heading1: "FY 2013",
        Heading2: "Quarter 4",
        PDF: "https://educomp.com/Data/Q4SO_13.pdf",
        isActive: 1
    },
    {
        id: 20,
        Heading1: "FY 2012",
        Heading2: "Quarter 1",
        PDF: "https://educomp.com/Data/Q1SO_12.pdf",
        isActive: 1
    },
    {
        id: 21,
        Heading1: "FY 2012",
        Heading2: "Quarter 2",
        PDF: "https://educomp.com/Data/Q2SO_12.pdf",
        isActive: 1
    },
    {
        id: 22,
        Heading1: "FY 2012",
        Heading2: "Quarter 3",
        PDF: "https://educomp.com/Data/Q3SO_12.pdf",
        isActive: 1
    },
    {
        id: 23,
        Heading1: "FY 2012",
        Heading2: "Quarter 4",
        PDF: "https://educomp.com/Data/Q4SO_12.pdf",
        isActive: 1
    },
    {
        id: 24,
        Heading1: "FY 2011",
        Heading2: "Quarter 1",
        PDF: "https://educomp.com/Data/Q1SO_11.pdf",
        isActive: 1
    },
    {
        id: 25,
        Heading1: "FY 2011",
        Heading2: "Quarter 2",
        PDF: "https://educomp.com/Data/Q2SO_11.pdf",
        isActive: 1
    },
    {
        id: 26,
        Heading1: "FY 2011",
        Heading2: "Quarter 3",
        PDF: "https://educomp.com/Data/Q3SO_11.pdf",
        isActive: 1
    },
    {
        id: 27,
        Heading1: "FY 2011",
        Heading2: "Quarter 4",
        PDF: "https://educomp.com/Data/Q4SO_11.pdf",
        isActive: 1
    }
]